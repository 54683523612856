import React from "react"
import { Formik, ErrorMessage } from 'formik';
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  state = { submitted: true }
  /* handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  } */

  render() {
    const { submitted } = this.state
    return (
      <Layout >
        <SEO title="Terms and Conditions" />
        <div className="content">
          <div id="quote">
              <>
                <h2 className="section-title quote-title">GET A FREE QUOTE.</h2>
                <div className="inner-text-page">
                  <Formik
                    initialValues={{ email: '', name: '', description: '' }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                        errors.email = 'Invalid email address';
                      }

                      if (!values.name) {
                        errors.name = 'Required';
                      }

                      if (!values.description) {
                        errors.description = 'Required';
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      let formData = new FormData()
                      formData.append('name', values.name);
                      formData.append('email', values.email);
                      formData.append('description', values.description);

                      fetch('https://getform.io/f/b6d63cfa-f39c-49a9-9493-f34dbb9eac86', {
                        method: "POST",
                        body: formData,
                        /* headers: {
                          'Content-Type': 'application/json',
                          'Accept': 'application/x.laravel.v1+json',
                          'Cache-Control': 'no-cache',
                        } */
                      }).then(() => {
                        navigate('/')
                        setSubmitting(false);
                      }).catch(() => {
                        navigate('/')
                        setSubmitting(false);
                      })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                        <>
                          <label className="form-label">Your Name</label>
                          <input
                            className="form-text-input"
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          <ErrorMessage name="name" component="div" className="form-error" />
                          <label className="form-label">Your Email</label>
                          <input
                            className="form-text-input"
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <ErrorMessage name="email" component="div" className="form-error" />
                          <label className="form-label">Project description</label>
                          <textarea
                            class="form-textarea"
                            placeholder="Description"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          ></textarea>
                          <ErrorMessage name="description" component="div" className="form-error" />


                          <a type="submit" className="button form-button" onClick={handleSubmit}>Submit</a>
                        </>
                      )}
                  </Formik>
                </div>
              </> 
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
